<template>
  <div>
    <dashboard-page-title  :showFilter="false" :showSearch="false" :showExportation="false" @addEvent="$router.push({name: 'addDocuments'})">المستندات</dashboard-page-title>

    <main-table :fields="fields" list_url="acceptance-docs" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الاسم', key: 'title', class: 'text-right' },
        { label: 'نوع الملف', key: 'type', class: 'text-right' },
        { label: 'مطلوب من', key: 'required_for', class: 'text-right', type: 'array' },
        {
          label: 'ضروري/اختياري',
          key: 'is_required',
          class: 'text-right',
          type: 'renderCell',
          renderCell: function (row) {
            const html = row.is_required
              ? '<span class="badge badge-danger">ضروري</span>'
              : '<span class="badge badge-secondary">اختياري</span>'
            return html
          }
        },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'primary',
              ifNavigate: true,
              routePath: 'edit-documents/:id'
            },
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: '/acceptance-docs',
              baseUrl: VUE_APP_VETE_LINK,
              titleHeader: 'ملف',
              question: 'هل متأكد أنك تريد مسح هذا العنصر',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'الغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
